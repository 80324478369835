<template>
    <div class="input-item">
        <div class="input-item-title">{{ title }}</div>
        <div class="approval-member-list">
            <div class="approval-member"
                    v-for="(item, index) in approvalMembersReal"
                    :key="item.id">
                <div class="approval-member-name">
                    <div v-if="typeClassify == 3 && index == approvalMembersReal.length - 1">
                        财务人员
                    </div>
                    <div v-else-if="typeClassify == 4">协议申报人</div>
                    <div v-else-if="index == 0 && typeClassify == 5">发起人</div>
                    <div v-else-if="index == 1 && typeClassify == 5">财务对接人</div>
                    <div v-else-if="index == approvalMembersReal.length - 3 && typeClassify == 8 ">
                        财务对接人
                    </div>
                    <div v-else-if="index == approvalMembersReal.length - 2 && typeClassify == 8">
                        财务领导
                    </div>
                    <div v-else-if="index == approvalMembersReal.length - 1 && typeClassify == 8">
                        秘书长
                    </div>
                    <div v-else-if="index == approvalMembersReal.length - 2 && typeClassify == 7">
                        秘书长
                    </div>
                    <div v-else-if="index == approvalMembersReal.length - 3 && typeClassify == 7">
                        财务领导
                    </div>
                    <div v-else-if="index == approvalMembersReal.length - 1 && typeClassify == 7">
                        财务对接人
                    </div>
                    <div v-else-if="index == 2 && typeClassify == 9">秘书长</div>
                    <div v-else-if="index == 1 && typeClassify == 9">财务领导</div>
                    <!--<div v-else-if="index == 1 && typeClassify == 9">财务人员</div>-->
                    <div v-else-if="index == 0 && typeClassify == 9">财务对接人</div>
                    <div v-else-if="index == 0 && typeClassify == 27">财务人员</div>
                    <div v-else>审批人</div>
                    <div class="approval-member-num">
                        {{ item.user_id !== -1 ? 1 : 0 }}人审批
                    </div>
                </div>
                <div class="master-name" @click="addMember(1, index)" v-if="item.user_id !== -1">
                     <span>{{item.true_name | sub_name }}</span>
                    <div>{{ item.true_name }}</div>
                </div>
                <div class="approval-members" v-else>
                    <div class="master-name-add flex-center" @click="addMember(1, index)">
                        <span>+</span>
                        <div>+</div>
                    </div>
                </div>
            </div>

            <div class="approval-member">
                <div class="approval-member-name">
                    <div>抄送人</div>
                    <div class="approval-member-num">
                        抄送{{ copyMembersReal.length }}人
                    </div>
                </div>
                <div class="approval-members">
                    <div class="approval-members" v-for="(item, index) in copyMembersReal" :key="item.id">
                        <div class="master-name">
                             <span>{{item.true_name | sub_name}}</span>
                            <i class="close el-icon-error" @click="delMember(index)"></i>
                            <div>{{ item.true_name }}</div>
                        </div>
                        <div class="master-name-line">+</div>
                    </div>
                    <div class="master-name-add flex-center" @click="addMember(2, 0)">
                        <span>+</span>
                        <div>+</div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="选择人员" :visible.sync="dialogVisible">
            <el-collapse>
                <el-collapse-item
                        v-for="item in memberChage"
                        :key="item.id"
                        :name="item.id"
                        :disabled="item.son.length == 0">
                    <template slot="title">
                        {{ item.name }}（{{ item.son.length }}人）
                    </template>
                    <div class="dialog-members">
                        <div class="master-name dialog-members-name"
                                v-for="member in item.son"
                                :key="member.id">
                            <span v-if="member.ischeck" @click="selectMember(member)">
                              {{member.true_name | sub_name}}
                              <i class="el-icon-success" :class="{ active: member.check }"></i>
                            </span>
                            <span v-else class="active">{{member.true_name | sub_name}}</span>
                            <div>{{ member.true_name }}</div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="subMember">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {sub_name} from "../tools";

export default {
        name: "approvalList",
        data() {
            return {
                dialogVisible: false,
                members: [],
                memberChage: [],
                addType: 1, // 1:radio  2:check
                addPosition: -1,
                approvalMembersReal: [],
                copyMembersReal: [],
            };
        },
        props: {
            title: {
                type: String,
                default: "审批流程",
            },
            typeClassify: {
                type: Number,
                default: 1,
            },
            isNoHave: {
                type: Boolean,
                default: true,
            },
            approvalMembers: {
                type: Array,
                default: () => [],
            },
            copyMembers: {
                type: Array,
                default: () => [],
            },
            trueName: {
                type: Object,
                default: () => {
                    return {
                        true_name: "",
                        user_id: "",
                    };
                },
            },
            society: {},
        },
        created() {
            this.init();
        },
        methods: {
            // 添加抄送人
            addMember(num, postion) {
                if (this.typeClassify !== 1) {
                    if (num === 1 && this.approvalMembersReal[postion].user_id !== -1) {
                        return;
                    }
                }
                let All = JSON.parse(JSON.stringify(this.members));
                let arr = JSON.parse(
                    JSON.stringify(
                        num === 1 ? this.approvalMembersReal : this.copyMembersReal
                    )
                ); //  已经选中的人
                let arr2 = JSON.parse(
                    JSON.stringify(
                        num === 1 ? this.copyMembersReal : this.approvalMembersReal
                    )
                ); //  不可以选中的人
                if (num === 1) {
                    this.approvalMembersReal.map((item, index) => {
                        if (index !== postion) arr2.push(item);
                    });
                }
                this.dialogVisible = true;
                this.addType = num;
                this.addPosition = postion;
                All.map((item) => {
                    item.son.map((sons) => {
                        arr.map((items) => {
                            //  人员中那些人员显示选中
                            if (items.user_id === sons.user_id) {
                                sons.check = true;
                            }
                        });
                        arr2.map((itemss) => {
                            //  人员中哪些人不可以选择
                            if (itemss.user_id === sons.user_id) {
                                sons.ischeck = false;
                            }
                        });
                    });
                });
                this.memberChage = All;
            },
            subMember() {
                let selectMember = [];
                this.memberChage.map((member) => {
                    member.son.map((son) => {
                        if (son.check) {
                            selectMember.push(son);
                        }
                    });
                });
                if (selectMember.length < 1) {
                    this.$message.error("请选择人员");
                } else {
                    if (this.addType === 1) {
                        this.approvalMembersReal.splice(this.addPosition, 1, selectMember[0]);
                        this.$emit(
                            "setApproval",
                            this.approvalMembersReal
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",")
                        );
                    } else {
                        this.copyMembersReal = selectMember;
                        this.$emit(
                            "setMember",
                            this.copyMembersReal
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",")
                        );
                    }
                    this.addPosition = -1;
                    this.dialogVisible = false;
                }
            },
            selectMember(item) {
                this.memberChage.map((member) => {
                    member.son.map((son) => {
                        if (this.addType === 1) {
                            if (item.check) {
                                son.check = false;
                            } else {
                                son.check = son.user_id === item.user_id;
                            }
                        } else {
                            if (item.check) {
                                if (son.user_id === item.user_id) {
                                    son.check = false;
                                }
                            } else {
                                if (son.user_id === item.user_id) {
                                    son.check = true;
                                }
                            }
                        }
                    });
                });
            },
            // 删除抄送人
            delMember(index) {
                this.copyMembersReal.splice(index, 1);
                this.$emit(
                    "setMember",
                    this.copyMembersReal
                        .map((item) => {
                            return item.user_id;
                        })
                        .join(",")
                );
            },
            //  页面加载时请求
            init() {
                if (this.typeClassify !== 4) {
                    this.$api.apiContent
                        .getApprovalMembers({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: this.typeClassify,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    type: this.typeClassify,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
                            if (res.data.yiji) {
                                this.approvalMembersReal.push(res.data.yiji);
                            } else {
                                this.approvalMembersReal.push({user_id: -1});
                            }
                            if (
                                this.typeClassify !== 1 &&
                                this.typeClassify !== 2 &&
                                // this.typeClassify !== 5 &&
                                this.typeClassify !== 6
                            ) {
                                if (
                                    res.data.erji ||
                                    this.typeClassify === 3 ||
                                    this.typeClassify === 5 ||
                                    this.typeClassify === 7 ||
                                    this.typeClassify === 8 ||
                                    this.typeClassify === 9
                                ) {
                                    this.approvalMembersReal.push(
                                        res.data.erji && res.data.erji.user_id
                                            ? res.data.erji
                                            : {user_id: -1}
                                    );
                                }
                                if (
                                    this.typeClassify === 3 ||
                                    this.typeClassify === 7 ||
                                    this.typeClassify === 8 ||
                                    this.typeClassify === 9
                                ) {
                                    this.approvalMembersReal.push(
                                        res.data.sanji && res.data.sanji.user_id
                                            ? res.data.sanji
                                            : {user_id: -1}
                                    );
                                    if (this.typeClassify === 7) {
                                        this.approvalMembersReal.push(
                                            res.data.siji && res.data.siji.user_id
                                                ? res.data.siji
                                                : {user_id: -1}
                                        );
                                    }
                                }
                            }
                            if (
                                this.typeClassify === 1
                            ) {
                                this.copyMembersReal = res.data.chao ? res.data.chao : this.copyMembers;
                                this.$emit(
                                    "setMember",
                                    this.copyMembersReal
                                        .map((item) => {
                                            return item.user_id;
                                        })
                                        .join(",")
                                );
                            }
                            this.$emit(
                                "setApproval",
                                this.approvalMembersReal
                                    .map((item) => {
                                        return item.user_id;
                                    })
                                    .join(",")
                            );
                        })
                        .catch(() => {
                            this.approvalMembersReal = [];
                            this.copyMembersReal = [];
                        });
                }
                this.$api.apiContent
                    .getAllUser({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.members = res.data;
                        this.members.map((item) => {
                            item.son.map((items) => {
                                items.check = false; //  是否被选择
                                items.ischeck = true; //  是否可以选择
                            });
                        });
                    })
                    .catch(() => {
                        this.members = [];
                    });
            },
            getFinance() {
                this.$api.apiContent
                    .getFinance({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        type: this.typeClassify,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                type: this.typeClassify,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        if (this.typeClassify === 2) {
                            this.copyMembersReal = [res.data.yiji];
                            if (res.data.chao) {
                                this.copyMembersReal = [res.data.yiji, res.data.chao[0]];
                            }
                            this.$emit(
                                "setMember",
                                this.copyMembersReal
                                    .map((item) => {
                                        return item.user_id;
                                    })
                                    .join(",")
                            );
                        } else if (this.typeClassify === 7 || this.typeClassify === 3) {
                            this.$set(
                                this.approvalMembersReal,
                                this.approvalMembersReal.length - 1,
                                res.data.yiji
                            );
                            this.$emit(
                                "setApproval",
                                this.approvalMembersReal
                                    .map((item) => {
                                        return item.user_id;
                                    })
                                    .join(",")
                            );
                        } else if (this.typeClassify === 5) {
                            this.$set(
                                this.approvalMembersReal,
                                this.approvalMembersReal.length - 1,
                                res.data.erji
                            );
                            this.$emit(
                                "setApproval",
                                this.approvalMembersReal
                                    .map((item) => {
                                        return item.user_id;
                                    })
                                    .join(",")
                            );
                        } else {
                            this.$set(this.approvalMembersReal, 0, res.data.yiji);
                            this.$emit(
                                "setApproval",
                                this.approvalMembersReal
                                    .map((item) => {
                                        return item.user_id;
                                    })
                                    .join(",")
                            );
                        }
                        if (
                            this.typeClassify !== 2
                        ) {
                            this.copyMembersReal = res.data.chao ? res.data.chao : [];
                            this.$emit(
                                "setMember",
                                this.copyMembersReal
                                    .map((item) => {
                                        return item.user_id;
                                    })
                                    .join(",")
                            );
                        }
                    });
            },
        },
        watch: {
            trueName() {
                if (this.trueName.true_name) {
                    this.approvalMembersReal = [this.trueName];
                } else {
                    this.approvalMembersReal = [];
                }
            },
            copyMembers() {
                this.copyMembersReal = this.copyMembers;
            },
            society() {
                if (this.society) this.getFinance();
            },
        },
    filters: {
        sub_name(str) {
            return sub_name(str)
        },
    },
    };
</script>
<style scoped lang="scss">
    .input-item {
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        .dialog-members {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 30px;
            border-top: 1px solid #f0f2f5;

            .dialog-members-name {
                margin-left: 20px;

                span {
                    cursor: pointer;
                    position: relative;

                    i {
                        position: absolute;
                        top: -5px;
                        right: -5px;
                        color: #f0f2f5;
                        font-size: 26px;
                        width: 24px;
                        height: 24px;
                        border-radius: 25px;
                        background: white;
                        overflow: hidden;

                        &.active {
                            color: #13ce66;
                            background: white;
                        }
                    }
                }

                .active {
                    background: #666;
                }
            }
        }

        .approval-member-list {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &:before {
                position: absolute;
                background: #3b77e7;
                border-radius: 13px;
                width: 1px;
                top: 56px;
                bottom: 56px;
                left: 16px;
                content: "";
                z-index: 1;
            }

            .approval-member {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #f0f0f0;
                padding: 20px;

                &:after {
                    position: absolute;
                    background: #3b77e7;
                    border-radius: 13px;
                    width: 13px;
                    height: 13px;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 10px;
                    content: "";
                }

                .approval-member-name {
                    font-size: 20px;
                    color: #000000;
                    margin-left: 30px;

                    .approval-member-num {
                        font-size: 16px;
                        color: #999999;
                        margin-top: 12px;
                    }
                }

                .approval-members {
                    display: flex;
                    flex-direction: row;

                    .master-name-line {
                        padding: 0 25px;
                        font-size: 16px;
                        color: #3b77e7;
                        align-self: center;
                        margin-bottom: 18px;
                    }

                    .master-name-add {
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;

                        > div {
                            font-size: 12px;
                            color: white;
                            text-align: center;
                            margin-top: 5px;
                        }

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            width: 56px;
                            height: 56px;
                            border-radius: 56px;
                            border: 1px dotted #3b77e7;
                            text-align: center;
                            /*line-height: 24px;*/
                            font-size: 36px;
                            color: #3b77e7;
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        .master-name {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            > div {
                font-size: 12px;
                color: #999999;
                text-align: center;
                margin-top: 5px;
            }

            .close {
                position: absolute;
                color: red;
                font-size: 26px;
                right: -10px;
                top: -5px;
                cursor: pointer;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                text-align: center;
                cursor: pointer;
                /*line-height: 24px;*/
                font-size: 18px;
                color: #fff;
                background-color: #3b77e7;
            }
        }

        .master-name-change {
            color: #3b77e7;
            margin-left: 20px;
        }

        .input-item-title {
            width: 20%;
            text-align: center;
            font-size: 16px;
            margin-right: 20px;
            color: #000000;
            flex-shrink: 0;
            align-self: flex-start;
            margin-top: 50px;
        }

        .input-item-desc {
            width: 20%;
            align-self: flex-start;
            margin-top: 10px;
            margin-right: 20px;
            text-align: center;
            font-size: 16px;
            color: #000000;
            flex-shrink: 0;
        }

        .el-input,
        .el-select,
        .el-textarea,
        .el-radio-group,
        .el-date-editor,
        .el-upload {
            flex-grow: 1;
            resize: none;
        }
    }
</style>
